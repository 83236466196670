/** @jsxImportSource react */
import { useAuth } from '@react/services/auth';
import { eventNames, useEvents } from '@react/services/events';
import Modal, {
  HeaderWithClose,
  ModalBody,
} from '@react/shared/components/NewModal';
import Wizard from '@react/wizards/components/Wizard';
import WizardPage from '@react/wizards/components/WizardPage';
import WizardContextProvider from '@react/wizards/WizardContextProvider';
import {
  AGENTSIGNUP,
  CUSTOMERSIGNUPCONFIRMATION,
  INFOPROMPT,
  LOGINSIGNUP,
  LOGINWITHPASSWORD,
  MAGICLINKEXPIRED,
  MAGICLINKSUCCESS,
  OTPLOGIN,
  PROIVDERSIGNUP,
  PROVIDERCLAIMNEEDSAPPROVAL,
  PROVIDERCOMMUNITYNOTFOUND,
  PROVIDERCONFIRMATION,
  PROVIDERFINDCOMMUNITY,
  RESETPASSWORD,
  SIGNUP,
} from '@sly/core/constants/auth';
import { AGENT_ND_ROLE, PROVIDER_OD_ROLE } from '@sly/core/constants/roles';
import dynamic from 'next/dynamic';
import React from 'react';

import { useWizard } from '../withWizard';

const LoginSignup = dynamic(
  /* webpackChunkName: "LoginSignup" */ () => import('./LoginSignup')
);

const InfoPrompt = dynamic(
  /* webpackChunkName: "InfoPrompt" */ () => import('./InfoPrompt')
);

const CustomerSignupConfirmation = dynamic(
  /* webpackChunkName: "CustomerSignupConfirmation" */ () =>
    import('./CustomerSignupConfirmation')
);

const LoginWithPassword = dynamic(
  /* webpackChunkName: "LoginWithPassword" */ () =>
    import('./LoginWithPassword')
);

const MagicLinkExpired = dynamic(
  /* webpackChunkName: "MagicLinkExpired" */ () => import('./MagicLinkExpired')
);

const MagicLinkSuccess = dynamic(
  /* webpackChunkName: "MagicLinkSuccess" */ () => import('./MagicLinkSuccess')
);

const OtpLogin = dynamic(
  /* webpackChunkName: "OtpLogin" */ () => import('./OtpLogin')
);

const ProviderAgentSignup = dynamic(
  /* webpackChunkName: "ProviderAgentSignup" */ () =>
    import('./ProviderAgentSignup')
);

const ProviderConfirmation = dynamic(
  /* webpackChunkName: "ProviderConfirmation" */ () =>
    import('./ProviderConfirmation')
);

const ProviderFindCommunity = dynamic(
  /* webpackChunkName: "ProviderFindCommunity" */ () =>
    import('./ProviderFindCommunity')
);

const ResetPassword = dynamic(
  /* webpackChunkName: "ResetPassword" */ () => import('./ResetPassword')
);

const Signup = dynamic(
  /* webpackChunkName: "Signup" */ () => import('./Signup')
);
const stepToTitleMap = {
  [LOGINSIGNUP]: 'Log in or sign up',
  [LOGINWITHPASSWORD]: 'Log in',
  [SIGNUP]: 'Create an account',
  [RESETPASSWORD]: 'Having trouble logging in?',
  [OTPLOGIN]: 'Confirm your phone number',
  [PROIVDERSIGNUP]: 'Create a community manager account',
  [AGENTSIGNUP]: 'Create a partner agent account',
  [PROVIDERFINDCOMMUNITY]:
    'What is the name of the community you want to manage?',
  [INFOPROMPT]: 'Finish sign up',
};

export const AuthWizardForm = ({
  initialValues,
  isOpen,
  hasPassword = true,
  hasPreference = true,
  initialStep,
  authenticatedCancel,
  authenticatedSuccess,
  modal = false,
  additionalModalProps = {},
}) => {
  const { goToStep, getCurrentStep, reset } = useWizard();

  const { getInitialStep } = useAuth();
  const { events } = useEvents();

  const authCancel = (foreground) => {
    authenticatedCancel();
    reset();
    if (foreground) {
      events.track(eventNames.ModalExited, {
        icon: 'Close',
        cta: 'Close modal',
        form: 'Auth',
        location: 'modal',
      });
    } else {
      events.track(eventNames.ModalExited, {
        cta: 'Close auth form',
        location: 'Auth',
      });
    }
  };

  const authSuccess = (user) => {
    authenticatedSuccess(user);
    reset();
  };

  const getTitle = () => {
    const currentStep = getCurrentStep();
    if (additionalModalProps?.isProvider && currentStep === LOGINSIGNUP) {
      return 'Sign in to your Seniorly Partner account';
    }
    return stepToTitleMap?.[currentStep];
  };

  const Component = modal ? Modal : React.Fragment;

  return (
    <Component open={isOpen} onClose={() => authCancel()}>
      <HeaderWithClose onClose={() => authCancel(true)}>
        {getTitle()}
      </HeaderWithClose>
      <ModalBody>
        <Wizard
          initialStep={getInitialStep(initialStep)}
          initialValues={initialValues}
          hideButtons
          noDefaultSubmit
        >
          <WizardPage
            name={LOGINSIGNUP}
            Component={LoginSignup}
            additionalModalProps={additionalModalProps}
          />

          <WizardPage
            name={SIGNUP}
            Component={Signup}
            hasPassword={hasPassword}
            hasPreference={hasPreference}
          />
          <WizardPage
            name={CUSTOMERSIGNUPCONFIRMATION}
            Component={CustomerSignupConfirmation}
            onSubmit={authSuccess}
          />
          <WizardPage
            authSuccess={authSuccess}
            name={LOGINWITHPASSWORD}
            Component={LoginWithPassword}
          />
          <WizardPage name={RESETPASSWORD} Component={ResetPassword} />
          <WizardPage name={MAGICLINKSUCCESS} Component={MagicLinkSuccess} />
          <WizardPage name={MAGICLINKEXPIRED} Component={MagicLinkExpired} />
          <WizardPage
            onSubmit={authSuccess}
            name={OTPLOGIN}
            Component={OtpLogin}
          />
          <WizardPage
            onSubmit={authSuccess}
            name={INFOPROMPT}
            Component={InfoPrompt}
          />
          <WizardPage
            name={PROIVDERSIGNUP}
            role={PROVIDER_OD_ROLE}
            onSubmit={(data) => {
              if (additionalModalProps?.communityName) {
                authSuccess(data);
              } else {
                goToStep(PROVIDERFINDCOMMUNITY);
              }
            }}
            Component={ProviderAgentSignup}
            additionalModalProps={additionalModalProps}
          />
          <WizardPage
            name={PROVIDERFINDCOMMUNITY}
            role={PROVIDER_OD_ROLE}
            Component={ProviderFindCommunity}
          />
          <WizardPage
            name={AGENTSIGNUP}
            role={AGENT_ND_ROLE}
            onSubmit={authSuccess}
            Component={ProviderAgentSignup}
          />

          <WizardPage
            name={PROVIDERCLAIMNEEDSAPPROVAL}
            onSubmit={authSuccess}
            Component={ProviderConfirmation}
          />
          <WizardPage
            name={PROVIDERCONFIRMATION}
            Component={ProviderConfirmation}
          />
          <WizardPage
            name={PROVIDERCOMMUNITYNOTFOUND}
            Component={ProviderConfirmation}
          />
        </Wizard>
      </ModalBody>
    </Component>
  );
};

export default function AuthWizardModal(props) {
  return (
    <WizardContextProvider>
      <AuthWizardForm modal {...props} />
    </WizardContextProvider>
  );
}
