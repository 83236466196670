/** @jsxImportSource react */
import React from 'react';

const svg = require('!raw-loader!./svg/seniorlyLogo-regular.svg').default;

const Logo = (props) => (
  <div
    dangerouslySetInnerHTML={{ __html: svg }}
    {...props}
    data-tp-id='gen-div-1a428561-d55e-48b2-8e06-0f17a2a92cc3'
  />
);

Logo.defaultProps = {
  palette: 'primary',
};

export default Logo;
