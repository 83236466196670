import React from 'react';
import { phoneFormatter } from '@common/helpers/phone';
import cx from 'classnames';
// when in qwik it uses qwik system because of vite config alias and in next system because of babel config, DO NOT USE ANY HOOKS IN THIS(LITE) COMPONENT
import { Link } from 'sly/system';
import { node, bool, string } from 'proptypes';

const HeaderAgentPhNo = ({
  className,
  isTopBar,
  showHeaderAgentPhNo,
  InfoIcon
}) => {
  const phoneNumber = showHeaderAgentPhNo ? '8558667661' : '8558667689';
  return (
    <div className={cx(!isTopBar && 'min-w-[152px]', className && className)}>
      {isTopBar && (
        <div className='font-b-s flex justify-center bg-viridian-lighter-90 py-2 md:hidden'>
          Talk with a local advisor for free
          <Link
            className='pl-1 text-viridian-base'
            href={'tel:+1' + phoneNumber}
          >
            {phoneFormatter(phoneNumber)}
          </Link>
        </div>
      )}
      {!isTopBar && (
        <div
          className={cx(
            'mr-5 flex rounded bg-viridian-lighter-90',
            'font-b-s py-3 px-2 text-viridian-base'
          )}
        >
          <div className='flex items-center'>
            <Link className='w-max' href={'tel:+1' + phoneNumber}>
              {phoneFormatter(phoneNumber)}
            </Link>
            {InfoIcon && <InfoIcon />}
          </div>
        </div>
      )}
    </div>
  );
};

HeaderAgentPhNo.propTypes = {
  className: string,
  isTopBar: string,
  showHeaderAgentPhNo: bool,
  InfoIcon: node
};
export default HeaderAgentPhNo;
