export const DASHBOARD_PATH = '/dashboard';

const FAMILY_DASHBOARD_PATH = `${DASHBOARD_PATH}/family`;
const AGENT_DASHBOARD_PATH = `${DASHBOARD_PATH}/agent`;

export const NEWFAMILIES = 'new';
export const PROSPECTING = 'prospecting';
export const CONNECTED = 'connected';
export const CLOSED = 'closed';
export const WON = 'won';
export const ALL = 'all';
const familyTypes = [NEWFAMILIES, PROSPECTING, CONNECTED, CLOSED, WON];

export const OVERDUE = 'overdue';
export const UPCOMING = 'upcoming';
export const TODAY = 'today';
export const COMPLETED = 'completed';
const taskTypes = [TODAY, OVERDUE, UPCOMING, COMPLETED];

export const PENDING = 'pending';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';

const reviewStatus = [PENDING, APPROVED, REJECTED];

const familyTypesExpanded = [ALL, ...familyTypes];

export const DASHBOARD_ACCOUNT_PATH = '/homebase/settings';
export const FAMILY_DASHBOARD_HOME_PATH = '/homebase';
export const FAMILY_DASHBOARD_FAVORITES_PATH = `/homebase/saved`;
export const AGENT_DASHBOARD_FAMILIES_PATH = `${DASHBOARD_PATH}/families?tab=new`;
export const AGENT_DASHBOARD_TASKS_PATH = `${DASHBOARD_PATH}/tasks?tab=today`;

export const AGENT_DASHBOARD_INVOICE_PATH = `${DASHBOARD_PATH}/invoices`;

export const AGENT_DASHBOARD_MESSAGES_PATH = `${DASHBOARD_PATH}/messages?tab=AgentClient`;

export const PARTNER_DASHBOARD_HOME_PATH = `${DASHBOARD_PATH}/stats`;

export const DASHBOARD_COMMUNITIES_PATH = `${DASHBOARD_PATH}/communities`;

export const DASHBOARD_USERS_PATH = `${DASHBOARD_PATH}/users`;
export const DASHBOARD_ORGS_PATH = `${DASHBOARD_PATH}/organizations`;

export const SUMMARY = 'summary';
export const ACTIVITY = 'activity';
export const FAMILY_DETAILS = 'family-details';
export const COMMUNITIES = 'communities';
export const COMMUNITY_REFERRALS = 'community-referrals';
export const PARTNER_AGENTS = 'partner-agents';
export const PARTNER = 'partner';
export const TASKS = 'tasks';
export const MESSAGES = 'messages';
export const EMAILS = 'emails';
export const PROFILE = 'profile';
export const PRICING = 'pricing';
export const NEWPRICING = 'new-pricing';
export const LISTINGS = 'listings';
export const PHOTOS = 'photos';
export const SERVICES = 'services';
export const CLIENTS = 'clients';
export const AGENT_DETAILS = 'agent-details';
export const CONTACTS = 'contacts';
export const EDITS = 'edits';
export const USERS = 'users';
export const ADMIN = 'admin';
export const AGENT = 'agent';
export const ADDITIONAL_INFO = 'additional-info';
export const REVIEWS = 'reviews';
export const MARKETING = 'marketing';
export const INTERESTED_COMMUNITIES = 'interested-community';

export const ADMIN_DASHBOARD_AGENTS_PATH = `${DASHBOARD_PATH}/agents`;

export const RESOURCE_CENTER_PATH = '/resource-center';
export const RESOURCE_CENTER_AUTHOR_PATH = `${RESOURCE_CENTER_PATH}/author/:slug`;
export const RESOURCE_CENTER_ARTICLE_PATH = `${RESOURCE_CENTER_PATH}/:topic/:slug`;
export const RESOURCE_CENTER_TOPIC_PATH = `${RESOURCE_CENTER_PATH}/:topic`;
export const RESOURCE_CENTER_SEARCH_PATH = `${RESOURCE_CENTER_PATH}/search/:searchBy`;
export const RESOURCE_CENTER_SITEMAP_PATH = `/sitemap${RESOURCE_CENTER_PATH}`;
export const RESOURCE_CENTER_AUTHOR_INDEX_PATH = `${RESOURCE_CENTER_PATH}/authors`;
export const RESOURCE_CENTER_REVIEWER_INDEX_PATH = `${RESOURCE_CENTER_PATH}/reviewers`;
export const RESOURCE_CENTER_REVIEWER_PATH = `${RESOURCE_CENTER_PATH}/reviewer/:slug`;

export const DASHBOARD_REVIEWS_PATH = `${DASHBOARD_PATH}/reviews?tab=pending`;

// Secure (not logged in) routes
export const SECURE_PATH = `${DASHBOARD_PATH}/secure`;
export const SECURE_DASHBOARD_FAMILIES_INDEX_PATH = `${SECURE_PATH}/families`;
export const SECURE_DASHBOARD_FAMILIES_DETAILS_PATH = `${SECURE_PATH}/families/:id/:tab?`;

// Client referral
export const REFERRAL_BUILDER = 'communityReferralBuilder';
